<template>
    <b-overlay :show="loading">
    
        <b-card
        >
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click.prevent="add"
                variant="primary"
                v-if="allowCreate()"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                {{$t('Add')}}
              </b-button>
              
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                :label="$t('Filter')"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
      
            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="audits"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                
              >
                <template #cell(order)="row">
                  <strong class="text-center">
                    {{ (row.index + 1) }}
                  </strong>
                </template>
    
                <template #cell(nama)="{item}">{{item.asset_id}}</template>
                <template #cell(satuan)="{item}">{{item.asset && item.asset.satuan ? item.asset.satuan.satuan : '-'}}</template>
                <template #cell(status)="{item}">
                    <b-badge v-if="item.status == 1" variant="light-success">
                        Selesai
                    </b-badge>
                    <b-badge v-else variant="light-danger">
                        Belum Selesai
                    </b-badge>
                </template>
      
                <template #cell(actions)="{item}">
                    <b-button variant="outline-info" class="mr-1" size="sm" @click.prevent="$router.push(`/asset-audit/detail/${item.id}`)">
                        <feather-icon icon="EyeIcon" />
                    </b-button>
                      <b-button
                        v-if="allowUpdate() && item.status == 0"
                        title="'Ubah'"
                        size="sm"
                        @click="edit(item)"
                        class="mr-1"
                        variant="outline-info"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        v-if="allowDelete()"
                        title="'Hapus'"
                        size="sm"
                        @click="remove(item)"
                        class="mr-1"
                        variant="outline-danger"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                </template>
              </b-table>
            </b-col>
      
            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>

        <!-- MOdal -->
        <b-modal id="form-audit" no-close-on-backdrop no-close-on-esc>
            <b-form-group class="mb-2">
                <label for=""><strong>Tanggal Audit</strong></label>
                <b-form-input type="date" v-model="form.tanggal"></b-form-input>
            </b-form-group>
            <b-form-group class="mb-2">
                <label for=""><strong>Keterangan</strong></label>
                <b-form-textarea v-model="form.keterangan"></b-form-textarea>
            </b-form-group>
            <template #modal-footer>
                <b-button @click.prevent="submit" variant="primary" :disable="!form.tanggal">Simpan</b-button>
            </template>
        </b-modal>
        <!-- / -->
    </b-overlay>
  </template>
  
  <script>
  import Ripple from 'vue-ripple-directive'
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import {
    BTable,
    BFormTextarea,
    BOverlay,
    VBTooltip,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BModal,
    BRow,
    BCard,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
  } from "bootstrap-vue";
  
  export default {
    components: {
        BCard,
      BCardActions,ToastificationContent,
      VBTooltip,
      BModal,
      BDropdown,
      BDropdownItem,
      BTable,
      BFormTextarea,
      BOverlay,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BFormCheckbox
    },
    directives: {
      "b-tooltip": VBTooltip,
      Ripple,
    },
    data() {
      return {
        loading: false,
        id:null,
        perPage: 10,
        pageOptions: [10, 30, 50],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        fields: [
          {
            key: "order",
            label: "No",
          },
          {
            key: "no",
            label: "No. Audit",
          },
          { key: "tanggal", label: "Tanggal", sortable: true },
          { key: "status", label: "Status" },
          { key: "actions", label: "Aksi" }
        ],
        audits: [],
        form: {
            id_gudang: null,
            tanggal: null,
            id_karyawan: null,
            keterangan: null
        }
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({ text: f.label, value: f.key }));
      },
    },
    methods: {
      add() {
        this.resetForm()
        this.$bvModal.show('form-audit')
      },
      toggleLoading(val) {
        this.loading = val
      },
      remove(item ){
        this.$swal({
          title: 'Anda yakin?',
          text: `Data mutasi ini akan dihapus`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            item.fungsi = 1 // soft delete
            this.$store.dispatch('asset/saveAudit', [item])
            .then(() => {
                this.getAudit()
            })
            .catch(e => {
              this.displayError(e)
              return false
            })
          }
        })
      },
      resetForm() {
        this.id = null
        delete this.form.id
        this.form.id_gudang = null
        this.form.tanggal = this.getCurrentDate()
        this.form.id_karyawan = this.user.id
        this.form.keterangan = null
        if(this.myGudang) this.form.id_gudang = this.myGudang.id
      },
      async submit() {
        try {
            if(!this.form.tanggal) {
                this.displayError({
                    message: 'Harap isi tanggal audit!'
                })
                return false
            }

            if(this.id) this.form.id = this.id

            this.loading = true
            const audit = await this.$store.dispatch('asset/saveAudit', [this.form])
            this.loading = false
            await this.getAudit()
            if(!this.id) {
                this.$router.push(`/asset-audit/detail/${audit.id}`)
            }
            else {
              this.$bvModal.hide('form-audit')
                this.resetForm()
            }
        }
        catch(e) {
            this.loading = false
            this.displayError(e)
            return false
        }
      },
      edit(item) {
        this.id = item.id
        this.form.tanggal = item.tanggal
        this.form.keterangan = item.keterangan
        this.$bvModal.show('form-audit')
      },
      async getAudit() {
        const params = {order: 'desc'}
        if(this.isAdminGudang || this.isAdminAsset) {
          params.id_gudang = this.myGudang.id
        }
        this.audits = await this.$store.dispatch('asset/getAudit', params)
        this.totalRows = this.audits.length
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
    },
    async created() {
        this.resetForm()
        this.loading = true
        this.getAudit()
        this.loading = false
    }
  };
  </script>
  